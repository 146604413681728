<template>
  <v-app>
    <!-- <v-app-bar
      app
      color="teal lighten-4"
      dark
    >
      <div class="d-flex align-center">
        Audit Review Scheduler
      </div>

      <v-spacer></v-spacer>
      
    </v-app-bar> -->

    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),
};
</script>
